import { BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import React from 'react';

function WelcomeHeader({ userName }: { userName: string }) {
  return (
    <div>
      <BdsTypo variant="fs-24" bold="semi-bold" tag="h3">
        Boas vindas, {userName}!
      </BdsTypo>
      <BdsTypo tag="h3">Seja muito bem-vindo(a) ao Blip Go! COEX Beta!</BdsTypo>
      <br />
      <hr style={{ width: '90vw' }} />
    </div>
  );
}

export default WelcomeHeader;
