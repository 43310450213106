import { BdsGrid, BdsIcon, BdsTooltip } from 'blip-ds/dist/blip-ds-react';
import useTrack from 'hooks/useTrack';
import { COEX_QUICK_ACCESS_MODULE } from 'libs/trackingEvents';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function QuickAccessItem() {
  const { track } = useTrack();
  const navigate = useNavigate();

  const handleNavigateToQuickAccess = () => {
    track(COEX_QUICK_ACCESS_MODULE);
    navigate('/quick-access');
  };

  return (
    <BdsGrid>
      <BdsTooltip position="right-bottom" tooltip-text="Acessos rápidos">
        <BdsIcon
          style={{ cursor: 'pointer' }}
          color="white"
          onClick={handleNavigateToQuickAccess}
          name="organize-blocks"
          theme="solid"
        />
      </BdsTooltip>
    </BdsGrid>
  );
}

export default QuickAccessItem;
