import { AppBar, Drawer, IconButton, Toolbar } from '@mui/material';
import { BdsAvatar, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import Logout from 'components/Logout';
import { useAuth } from 'oidc-react';
import React, { useState } from 'react';
import blipAssistLogo from '../../assets/images/blip_assist.svg';
import './style.scss';

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { userData } = useAuth();
  const name = userData?.profile.FullName as string | undefined;

  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <>
      <AppBar className="app-top-bar" position="static">
        <Toolbar>
          <IconButton onClick={toggleDrawer(true)} edge="start" aria-label="menu">
            <BdsGrid direction="row" gap="2">
              <BdsIcon name="blip-chat" type="logo" />
              <BdsTypo class="text-white" variant="fs-14" bold="bold" lineHeight="double">
                Menu
              </BdsTypo>
            </BdsGrid>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <BdsGrid direction="column" padding="2" gap="3" class="drawer-content">
          <BdsGrid direction="row" justifyContent="space-between" alignItems="center">
            <img onClick={toggleDrawer(false)} src={blipAssistLogo} />
            <BdsIcon onClick={toggleDrawer(false)} name="close" size="x-large" class="cursor-pointer text-white" />
          </BdsGrid>
          <BdsGrid gap="1" direction="row" justifyContent="flex-start" alignItems="center">
            <BdsAvatar id="avatar" color="success" name={name} size="small"></BdsAvatar>
            {name && (
              <BdsTypo class="text-white" variant="fs-14" lineHeight="double">
                {name}
              </BdsTypo>
            )}
          </BdsGrid>
          <Logout />
        </BdsGrid>
      </Drawer>
    </>
  );
};

export default Header;
