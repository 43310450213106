// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .faq-container {
    max-width: 48rem !important;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 768px) {
  .faq-container {
    padding-top: 0 !important;
    margin: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/FaqWizard/style.scss"],"names":[],"mappings":"AAAA;EACE;IACE,2BAAA;IACA,qBAAA;EACF;AACF;AAEA;EACE;IACE,yBAAA;IACA,oBAAA;EAAF;AACF","sourcesContent":["@media (min-width: 768px) {\r\n  .faq-container {\r\n    max-width: 48rem !important;\r\n    margin-bottom: 1.5rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .faq-container {\r\n    padding-top: 0 !important;\r\n    margin: 0 !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
