import * as shopApiRoutes from 'libs/shopApiRoutes';
import { useAuth } from 'oidc-react';
import { useMemo } from 'react';
import { HubsportCreateContact, HubspotContactUpdate } from 'types/Hubspot';
import { RequestErrorBoundary } from 'utils/request';
import { ILogArgs, logger } from 'packs-template-baseweb';

const logArgs: ILogArgs = {
  className: 'useBlipShopApi',
};

const useBlipShopApi = () => {
  const { userData } = useAuth();
  const hubspotOwnerId = process.env.REACT_APP_HUBSPOT_OWNER_ID;
  const auth = process.env.REACT_APP_HUBSPOT_API_AUTH;

  const header = useMemo(() => {
    return {
      'Content-Type': 'application/json',
      Authorization: `Basic ${auth}`,
    };
  }, []);

  const createHubspotContactAndTicket = async (botId: string): Promise<HubsportCreateContact> => {
    logArgs.methodName = 'createHubspotContactAndTicket';
    const body = {
      subject: 'Plg',
      hubspotOwnerId: hubspotOwnerId,
      chatbotId: botId,
      email: userData?.profile.Email,
      firstName: userData?.profile.FirstName || 'no name',
      website: userData?.profile.website || 'no website',
      phone: userData?.profile.phone_number || 'no phone',
    };
    try {
      const res = await fetch(shopApiRoutes.HUBSPOT_CREATE_TICKET_CONTACT, {
        headers: header,
        method: 'POST',
        body: JSON.stringify(body),
      });
      return await RequestErrorBoundary(res, logArgs);
    } catch (error) {
      const err = error as Error;
      return Promise.reject(err.message);
    }
  };

  return {
    createHubspotContactAndTicket,
  };
};

export default useBlipShopApi;
