import { BdsChipTag, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import InsertDataModal from 'components/InsertDataModal';
import PauseAssistantModal from 'components/PauseAssistantModal';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { ContactStatus, HUBSPOT_ASSIST_PARAMETER } from 'libs/hubspotParameters';
import { PAUSE_ASSISTANT, RESUME_ASSISTANT } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React, { useEffect, useRef } from 'react';
import { BotStatus } from 'types/Bot';
import './style.scss';

function DashboardHeader() {
  const { whatsAppActivated, botConfiguration, instagramActivated, faqs, facebookUserData } = useAppContext();
  const { setBotStatus } = usePlgApi();
  const { updateHubspotContact } = usePacksApi();
  const { track } = useTrack();
  const { createToastError } = useToast();
  const insertDataModalRef = useRef<HTMLBdsModalElement | null>(null);
  const pauseAssistantModalRef = useRef<HTMLBdsModalElement | null>(null);
  const isActive = botConfiguration.status === BotStatus.Active;
  const isPaused = botConfiguration.status >= BotStatus.Paused;
  const isAssistTested = botConfiguration.status === BotStatus.Tested;
  const robotColor = isActive ? 'success-background' : isPaused ? 'warning-background' : 'system-background';
  const tagText = isActive ? 'ativo' : isPaused ? 'pausado' : 'desativado';
  const hasSubmittedAnyData = faqs?.length > 0 || facebookUserData?.isCatalogSubmitted;
  const userName = useAuth().userData?.profile.FullName as string;

  const pauseAssistant = async () => {
    track(PAUSE_ASSISTANT);
    pauseAssistantModalRef.current?.toggle();
  };

  const reactivateAssistant = async () => {
    if (botConfiguration.status === BotStatus.PausedWithNoData) {
      insertDataModalRef.current?.toggle();
      return;
    }
    try {
      await setBotStatus(BotStatus.Active);
      track(RESUME_ASSISTANT, { status: 'Success' });
      updateHubspotContact(HUBSPOT_ASSIST_PARAMETER, ContactStatus.Active, botConfiguration.botId);
    } catch (error) {
      const err = error as Error;
      track(RESUME_ASSISTANT, { status: 'Error', error: err.message });
      createToastError('Não foi possível realizar esta ação no momento', 'Tente novamente mais tarde');
    }
  };

  useEffect(() => {
    if (!whatsAppActivated && !isAssistTested) {
      setBotStatus(BotStatus.Created);
    } else if (!hasSubmittedAnyData) {
      setBotStatus(BotStatus.PausedWithNoData);
    } else if (hasSubmittedAnyData) {
      if (botConfiguration.status === BotStatus.PausedWithNoData || whatsAppActivated) {
        setBotStatus(BotStatus.Active);
      }
    }
  }, [botConfiguration.status, hasSubmittedAnyData, instagramActivated, setBotStatus, whatsAppActivated]);

  return (
    <div>
      <BdsPaper class="card-container">
        <BdsGrid gap="3" margin="none">
          <div className={'circle ' + robotColor}>
            <BdsIcon name="robot" />
          </div>
          <div>
            <BdsTypo variant="fs-14">Olá, eu sou o seu</BdsTypo>
            <BdsTypo variant="fs-20" bold="bold">
              Assistente Virtual!
            </BdsTypo>
            <BdsChipTag color={isPaused ? 'warning' : isActive ? 'success' : 'outline'}>{tagText}</BdsChipTag>
          </div>
        </BdsGrid>
        {isActive ? (
          <BdsGrid margin="t-3">
            <MaterialButton
              onClickCallback={pauseAssistant}
              text="Pausar assistente"
              startIconBdsName="pause"
              variant="secondary"
              isMobileView
            />
          </BdsGrid>
        ) : isPaused ? (
          <BdsGrid margin="t-3" direction="column">
            {!hasSubmittedAnyData && (
              <div className="no-data-alert-container">
                <BdsGrid justifyContent="space-between">
                  <BdsTypo variant="fs-14" italic>
                    Adicione assuntos à base de conhecimento para ativar seu assistente.
                  </BdsTypo>
                  <BdsIcon name="question" theme="solid" />
                </BdsGrid>
              </div>
            )}
            <MaterialButton
              onClickCallback={reactivateAssistant}
              text="Ativar assistente"
              startIconBdsName="play"
              isMobileView
            />
          </BdsGrid>
        ) : (
          <></>
        )}
      </BdsPaper>
      <InsertDataModal ref={insertDataModalRef} />
      <PauseAssistantModal ref={pauseAssistantModalRef} />
    </div>
  );
}

export default DashboardHeader;
