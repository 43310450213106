import { BdsButton, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import HelpBanner from 'components/HelpBanner';
import Sidebar from 'components/Sidebar';
import WelcomeHeader from 'components/WelcomeHeader';
import { useAppContext } from 'contexts/AppContext';
import useTrack from 'hooks/useTrack';
import {
  COEX_QUICK_ACCESS_MODULE_ADS,
  COEX_QUICK_ACCESS_MODULE_CREATE,
  COEX_QUICK_ACCESS_MODULE_SEND,
} from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React from 'react';

function QuickAccess() {
  const { track } = useTrack();
  const { botConfiguration } = useAppContext();
  const tenantId = process.env.REACT_APP_PACK_TENANT;
  const isProduction = process.env.NODE_ENV === 'production';
  const applicationDetail = isProduction ? 'blip.ai/application/detail' : 'hmg.blip.ai/application/detail';
  const createActiveMessagesUrl = `https://${tenantId}.${applicationDetail}/${botConfiguration.botId}/contents/messagetemplate`;
  const sendActiveMessagesUrl = `https://${tenantId}.${applicationDetail}/${botConfiguration.botId}/growth/activemessages`;
  const adsPerformanceUrl = `https://${tenantId}.${applicationDetail}/${botConfiguration.botId}/growth/clicktracker`;
  const userName = useAuth().userData?.profile.FullName as string;

  const handleAccessCreateMessages = () => {
    track(COEX_QUICK_ACCESS_MODULE_CREATE);
    window.open(createActiveMessagesUrl, '_blank');
  };

  const handleAccessSendMessages = () => {
    track(COEX_QUICK_ACCESS_MODULE_SEND);
    window.open(sendActiveMessagesUrl, '_blank');
  };

  const handleAccessAdsPerformance = () => {
    track(COEX_QUICK_ACCESS_MODULE_ADS);
    window.open(adsPerformanceUrl, '_blank');
  };

  return (
    <>
      <Sidebar />
      <BdsGrid direction="column" style={{ margin: '4rem 10rem' }}>
        <WelcomeHeader userName={userName} />
        <BdsGrid direction="column" gap="4">
          <BdsTypo variant="fs-32" margin={false}>
            Aviso:
          </BdsTypo>
          <BdsTypo>
            O Blip Go! COEX Beta é um produto ainda não lançado, por isso algumas funções serão abertas em uma nova guia
            do seu navegador no portal Blip.
          </BdsTypo>
          <BdsGrid gap="2">
            <BdsPaper width="336px">
              <BdsGrid direction="column" gap="2" padding="3" alignItems="center" style={{ textAlign: 'center' }}>
                <BdsTypo margin={false} variant="fs-24" tag="h3" bold="bold">
                  Crie mensagens para disparo em massa
                </BdsTypo>
                <BdsTypo>
                  Crie e envie para aprovação do Meta mensagens para sua empresa disparar em massa para seus contatos.
                </BdsTypo>
                <BdsButton onBdsClick={handleAccessCreateMessages}>Accessar</BdsButton>
              </BdsGrid>
            </BdsPaper>
            <BdsPaper width="336px">
              <BdsGrid direction="column" gap="2" padding="3" alignItems="center" style={{ textAlign: 'center' }}>
                <BdsTypo margin={false} variant="fs-24" tag="h3" bold="bold">
                  Dispare mensagens em massa
                </BdsTypo>
                <BdsTypo>Dispare mensagens em massa para o Whatsapp dos seus contatos.</BdsTypo>
                <BdsButton style={{ marginTop: '1.5rem' }} onBdsClick={handleAccessSendMessages}>
                  Accessar
                </BdsButton>
              </BdsGrid>
            </BdsPaper>
            <BdsPaper width="336px">
              <BdsGrid direction="column" gap="2" padding="3" alignItems="center" style={{ textAlign: 'center' }}>
                <BdsTypo margin={false} variant="fs-24" tag="h3" bold="bold">
                  Analise e performance dos anúncios (Ads)
                </BdsTypo>
                <BdsTypo>Analise como seus anúncios estão performando e como está a conversão no seu WhatsApp.</BdsTypo>
                <BdsButton onBdsClick={handleAccessAdsPerformance}>Accessar</BdsButton>
              </BdsGrid>
            </BdsPaper>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
      <HelpBanner
        moduleName="Dashboard"
        screenName="Dashboard"
        defaultMessage="Preciso de ajuda sobre o Blip Go!."
        show={true}
      />
    </>
  );
}

export default QuickAccess;
