import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import FaqWizardInput from 'components/FaqWizard/FaqWizardInput';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { FAQ_NEWFAQ_CLOSED, FAQ_NEWFAQ_CLOSE_CONFIRMED } from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function FaqWizard() {
  const navigate = useNavigate();
  const { track } = useTrack();
  const isMobileView = useMobileView();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [trackPayload, setTrackPayload] = useState({ typeQuestion: 'ADD_PELO_USER', questionName: 'LOJA_FISICA' });

  const handleCloseWizardClick = () => {
    track(FAQ_NEWFAQ_CLOSED, trackPayload);
    setShowAlertModal(true);
  };

  const closeWizard = () => {
    track(FAQ_NEWFAQ_CLOSE_CONFIRMED, trackPayload);
    navigate('/dashboard');
  };

  const updateTrackPayload = (payload: any) => {
    setTrackPayload(payload);
  };

  return (
    <>
      {!isMobileView && <Sidebar />}
      <div className="content faq-container">
        {!isMobileView && (
          <BdsGrid alignItems="center" margin="b-3" justifyContent="space-between" xxs="auto">
            <BdsGrid gap="1" alignItems="center">
              <BdsIcon class="cursor-pointer" onClick={handleCloseWizardClick} name="home" />
              <BdsTypo class="cursor-pointer" onClick={handleCloseWizardClick} variant="fs-14" bold="bold">
                Painel de Controle
              </BdsTypo>
              <BdsIcon name="arrow-right" />
              <BdsTypo variant="fs-14" bold="bold">
                Passo 1: O que preciso saber sobre a sua loja?
              </BdsTypo>
            </BdsGrid>
            <BdsButton onClick={handleCloseWizardClick} variant="secondary">
              Voltar
            </BdsButton>
          </BdsGrid>
        )}
        <FaqWizardInput setPageHeaderTracking={updateTrackPayload} />
        <BdsAlert open={showAlertModal}>
          <BdsAlertHeader variant="warning" icon="warning">
            Interromper configuração de assuntos?
          </BdsAlertHeader>
          <BdsAlertBody>Seu assistente só ficará ativo após concluir todos os passos.</BdsAlertBody>
          <BdsAlertActions>
            <BdsButton onClick={() => setShowAlertModal(false)} variant="secondary">
              Continuar aqui
            </BdsButton>
            <BdsButton onClick={closeWizard} variant="secondary">
              Voltar à home
            </BdsButton>
          </BdsAlertActions>
        </BdsAlert>
      </div>
    </>
  );
}

export default FaqWizard;
