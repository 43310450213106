import { BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { USER_LOGO } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React from 'react';
import './style.scss';

function NameInitials() {
  const { userData } = useAuth();
  const { track } = useTrack();
  const isMobileView = useMobileView();

  const getInitials = () => {
    const name = userData?.profile.FullName as string | undefined;
    const words = name?.split(' ');
    const initials = words?.map(word => word.charAt(0).toUpperCase()).slice(0, 2);
    return initials?.join('');
  };

  return (
    <>
      <BdsTooltip position={isMobileView ? 'left-bottom' : 'right-bottom'} tooltip-text={userData?.profile.FullName}>
        <div className="initials-circle" onClick={() => track(USER_LOGO)}>
          <BdsTypo>{getInitials()}</BdsTypo>
        </div>
      </BdsTooltip>
    </>
  );
}

export default NameInitials;
