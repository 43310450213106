import { BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';

type StepDisabledProps = {
  step: number;
  text: string;
};

function StepDisabled({ step, text }: StepDisabledProps) {
  const isMobile = useMobileView();

  return (
    <BdsPaper style={{ borderRadius: '1rem', opacity: '0.6', width: isMobile ? '' : '22rem' }}>
      <BdsGrid padding="3" direction="column">
        <BdsTypo variant="fs-14">Passo {step}:</BdsTypo>
        <BdsTypo bold="bold">{text}</BdsTypo>
        <br />
        <BdsTypo variant="fs-12">Termine o passo anterior para começar esse passo.</BdsTypo>
      </BdsGrid>
    </BdsPaper>
  );
}

export default StepDisabled;
