// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .container {
    margin: 2rem auto;
    width: 25vw;
  }
}

.container-activated-desktop {
  margin: 4rem 10rem;
}

.help-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EALF;IAMI,iBAAA;IACA,WAAA;EAGF;AACF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,eAAA;EACA,SAAA;EACA,WAAA;AAGF","sourcesContent":[".container {\r\n  margin: 1rem;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  @media (min-width: 768px) {\r\n    margin: 2rem auto;\r\n    width: 25vw;\r\n  }\r\n}\r\n\r\n.container-activated-desktop {\r\n  margin: 4rem 10rem;\r\n}\r\n\r\n.help-banner {\r\n  position: fixed;\r\n  bottom: 0;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
