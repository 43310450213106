import { BdsGrid, BdsIcon } from 'blip-ds/dist/blip-ds-react/components';
import DashboardItem from 'components/DashboardItem';
import Logout from 'components/Logout/Logout';
import NameInitials from 'components/NameInitials';
import QuickAccessItem from 'components/QuickAccessItem';
import React from 'react';
import './style.scss';

function Sidebar() {
  return (
    <div className="sidebar">
      <BdsGrid gap="4" alignItems="center" direction="column" justifyContent="center">
        <BdsIcon type="logo" name="blip-chat" size="brand" />
        <NameInitials />
        <DashboardItem />
        <QuickAccessItem />
        <Logout />
      </BdsGrid>
    </div>
  );
}

export default Sidebar;
