import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import useTrack from 'hooks/useTrack';
import { HELP_CLICKED } from 'libs/trackingEvents';
import React, { useEffect } from 'react';
import './style.scss';

type HelpBannerProps = {
  screenName: string;
  moduleName: string;
  defaultMessage: string;
  show?: boolean;
};

function HelpBanner({ screenName, moduleName, defaultMessage, show = false }: HelpBannerProps) {
  const { track } = useTrack();

  // const phoneNumber = '5511948377994';
  // const link = `https://wa.me/${phoneNumber}?text=${defaultMessage}`;

  const handleLinkClick = () => {
    track(HELP_CLICKED, { screenName, moduleName });
    const coexLink =
      'https://api.whatsapp.com/send/?phone=553192670115&text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20o%20Blip%20Go!%20COEX%20Beta';
    window.open(coexLink, '_blank');
  };

  useEffect(() => {
    setTimeout(() => {
      const helpContainer = document.querySelector('.help-container');
      if (helpContainer) {
        helpContainer.classList.add('closed');
      }
    }, 10000);
  }, []);

  return (
    show && (
      <BdsGrid class="help-container" xxs="12" direction="row" padding="y-1">
        <BdsGrid class="side-drawer" onClick={handleLinkClick} gap="2" padding="x-3">
          <BdsIcon name="message-ballon" type="icon" size="xx-large" />
          <BdsGrid direction="column">
            <BdsTypo variant="fs-14" bold="bold">
              Precisa de ajuda?
            </BdsTypo>
            <BdsTypo class="help-link" variant="fs-12" italic={true} lineHeight="double">
              Fale com a gente no WhatsApp.
            </BdsTypo>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    )
  );
}

export default HelpBanner;
