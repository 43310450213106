import { BdsGrid, BdsIcon, BdsTooltip } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function DashboardItem() {
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <BdsGrid>
      <BdsTooltip position="right-bottom" tooltip-text="Dashboard">
        <BdsIcon style={{ cursor: 'pointer' }} color="white" onClick={handleNavigateToDashboard} name="home" />
      </BdsTooltip>
    </BdsGrid>
  );
}

export default DashboardItem;
