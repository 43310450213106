import { currentActivationStep, firstDashboardVisit } from 'atoms/AppAtoms';
import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import FaqStep from 'components/ActivationSteps/FaqStep';
import InstagramStep from 'components/ActivationSteps/InstagramStep';
import SimulatorStep from 'components/ActivationSteps/SimulatorStep';
import WhatsAppStep from 'components/ActivationSteps/WhatsAppStep';
import AdvisoryModal from 'components/AdvisoryModal';
import CatalogPaperCard from 'components/CatalogPaperCard';
import DashboardHeader from 'components/DashboardHeader';
import FaqCard from 'components/FAQ/FaqCard';
import Header from 'components/Header';
import HelpBanner from 'components/HelpBanner';
import InstallationErrorHeader from 'components/InstallationErrorHeader';
import PrivateReplyBanner from 'components/PrivateReply/PrivateReplyBanner';
import Sidebar from 'components/Sidebar';
import SocialsCard from 'components/SocialsCard';
import WelcomeHeader from 'components/WelcomeHeader';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import usePacksApi from 'hooks/usePacksApi';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { CONSULTING_MODAL_SHOWED, INSTALLATION, OPENED } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function Dashboard() {
  const advisoryModalAlreadyShownLocalStorageName = 'AdvisoryModalAlreadyShown';
  const privateReplySurveyAlreadyShown = 'privateReplySurvey';
  const advisoryModalRef = useRef<HTMLBdsModalElement | null>(null);
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const { botConfiguration } = useAppContext();
  const [firstVisit, setFirstVisit] = useAtom(firstDashboardVisit);
  const [advisoryModalAlreadyShown, setAdvisoryModalAlreadyShown] = useState(
    localStorage.getItem(advisoryModalAlreadyShownLocalStorageName) === 'true',
  );
  const isMobile = useMobileView();
  const [showPrivateReply] = useState(localStorage.getItem(privateReplySurveyAlreadyShown) === null);
  const { pollInstallationStatusWithRetries } = usePacksApi();
  const { track } = useTrack();
  const [currentStep] = useAtom(currentActivationStep);
  const navigate = useNavigate();
  const hasActivated = botConfiguration.status >= 3;
  const remainingStepsTitles = [
    '',
    'Me ative em apenas 4 passos:',
    'Faltam só 3 passos para me ativar:',
    'Faltam só 2 passos para me ativar:',
    'Quase lá! Só mais 1 passo para me ativar:',
  ];
  const userName = useAuth().userData?.profile.FullName as string;

  const pollInstallationStatus = async (botId: string) => {
    try {
      await pollInstallationStatusWithRetries(botId);
    } catch (error) {
      track(INSTALLATION, { status: 'Error', error: error });
      setShowErrorHeader(true);
    }
  };

  // useEffect(() => {
  //   const showAdvisoryModal = () => {
  //     track(CONSULTING_MODAL_SHOWED);
  //     setAdvisoryModalAlreadyShown(true);
  //     localStorage.setItem(advisoryModalAlreadyShownLocalStorageName, 'true');
  //     advisoryModalRef.current?.toggle();
  //   };

  //   if (!advisoryModalAlreadyShown && hasActivated) {
  //     showAdvisoryModal();
  //   }
  // });

  useEffect(() => {
    if (firstVisit) {
      track(OPENED);
      if (!botConfiguration?.botId) {
        return navigate('/');
      }

      pollInstallationStatus(botConfiguration.botId);
      setFirstVisit(false);
    }
  }, []);

  return isMobile ? (
    <>
      <Header />
      {showErrorHeader && <InstallationErrorHeader hideError={() => setShowErrorHeader(false)} />}
      <div className="container">
        <BdsGrid direction="column" gap="3" padding="b-6">
          {/* <PrivateReplyBanner moduleName="Dashboard" screenName="Dashboard" show={showPrivateReply} /> */}
          {hasActivated ? (
            <>
              <DashboardHeader />
              {/* <SocialsCard /> */}
              <FaqCard />
              <CatalogPaperCard />
            </>
          ) : (
            <>
              <DashboardHeader />
              {/* <BdsTypo className="text-align-center" bold="bold">
                {remainingStepsTitles[currentStep]}
              </BdsTypo> */}
              <FaqStep />
              <SimulatorStep />
              {/* <WhatsAppStep /> */}
              {/* <InstagramStep /> */}
            </>
          )}
        </BdsGrid>
      </div>
      {/* <AdvisoryModal ref={advisoryModalRef} /> */}
      <div className="help-banner">
        <HelpBanner
          moduleName="Dashboard"
          screenName="Dashboard"
          defaultMessage="Preciso de ajuda sobre o Blip Assist."
          show={true}
        />
      </div>
    </>
  ) : (
    <>
      <Sidebar />
      {showErrorHeader && <InstallationErrorHeader hideError={() => setShowErrorHeader(false)} />}
      {/* <PrivateReplyBanner moduleName="Dashboard" screenName="Dashboard" show={showPrivateReply} /> */}
      {hasActivated ? (
        <BdsGrid direction="column" style={{ margin: '4rem 10rem' }} gap="4">
          <WelcomeHeader userName={userName} />
          <DashboardHeader />
          {/* <SocialsCard /> */}
          <BdsGrid gap="4">
            <FaqCard />
            <CatalogPaperCard />
          </BdsGrid>
        </BdsGrid>
      ) : (
        <BdsGrid direction="column" style={{ margin: '4rem 10rem' }}>
          <WelcomeHeader userName={userName} />
          <br />
          {/* <BdsTypo className="text-align-center" bold="bold">
              {remainingStepsTitles[currentStep]}
              </BdsTypo> */}
          <BdsGrid gap="3" direction="column" style={{ margin: '0 auto' }}>
            <DashboardHeader />
            <FaqStep />
            <SimulatorStep />
          </BdsGrid>
          {/* <WhatsAppStep /> */}
          {/* <InstagramStep /> */}
        </BdsGrid>
      )}
      {/* <AdvisoryModal ref={advisoryModalRef} /> */}
      <HelpBanner
        moduleName="Dashboard"
        screenName="Dashboard"
        defaultMessage="Preciso de ajuda sobre o Blip Go!."
        show={true}
      />
    </>
  );
}

export default Dashboard;
