import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import BotAvatar from 'components/FaqWizard/BotAvatar';
import React from 'react';
import './style.scss';

function FaqHelperMessage() {
  return (
    <>
      <BdsGrid>
        <BotAvatar />
      </BdsGrid>
      <BdsGrid class="faqhelper" alignItems="center">
        <BdsGrid padding="2">
          <BdsTypo>Aqui você pode criar, editar ou excluir assuntos que preciso saber sobre sua loja:</BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default FaqHelperMessage;
