// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-input-paper {
  border-radius: 1rem;
  box-shadow: 0px 0rem 0rem 0px rgba(0, 0, 0, 0);
  width: 32.875rem;
}

@media (max-width: 768px) {
  .faq-input-paper {
    width: 90%;
  }
}
.faq-item {
  margin-bottom: 0.75rem;
  box-shadow: 0px 0rem 0rem 0px rgba(0, 0, 0, 0);
  border-radius: 1rem;
}

.answer-text {
  padding: 0.625rem;
}

.faq-input-area {
  max-width: 30rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FAQ/FaqInput/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,8CAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;AACF;AAEA;EACE,sBAAA;EACA,8CAAA;EACA,mBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":[".faq-input-paper {\n  border-radius: 1rem;\n  box-shadow: 0px 0rem 0rem 0px rgba(0, 0, 0, 0);\n  width: 32.875rem;\n}\n\n@media (max-width: 768px) {\n  .faq-input-paper {\n    width: 90%;\n  }\n}\n\n.faq-item {\n  margin-bottom: .75rem;\n  box-shadow: 0px 0rem 0rem 0px rgba(0, 0, 0, 0);\n  border-radius: 1rem;\n}\n\n.answer-text {\n  padding: .625rem;\n}\n\n.faq-input-area {\n  max-width: 30rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
