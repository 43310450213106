import { BdsGrid, BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { LOGOUT } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React from 'react';
import './style.scss';

function Logout() {
  const { signOut } = useAuth();
  const { track } = useTrack();
  const isMobileView = useMobileView();
  const logoutText = 'Sair do sistema';

  const logout = async () => {
    track(LOGOUT);
    const urlRedirect = window.location.href;
    const urlEncoded = encodeURI(urlRedirect);
    const urlLogout = `${process.env.REACT_APP_AUTHORITY}/logout/redirect?returnDesiredUrl=${urlEncoded}`;
    await signOut();
    window.location.href = urlLogout;
  };

  return isMobileView ? (
    <BdsGrid
      onClick={logout}
      class="cursor-pointer"
      gap="1"
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <BdsIcon name="status" size="large" class="cursor-pointer text-white" />
      <BdsTypo class="text-white" variant="fs-14" lineHeight="double">
        {logoutText}
      </BdsTypo>
    </BdsGrid>
  ) : (
    <BdsTooltip position="bottom-center" tooltipText={logoutText}>
      <BdsIcon onClick={logout} id="logout-icon" size="x-large" name="status" />
    </BdsTooltip>
  );
}

export default Logout;
