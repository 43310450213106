import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react/components';
import { AppProvider } from 'contexts/AppContext';
import { MobileViewProvider } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { VERSION } from 'libs/esquentator';
import { OIDC } from 'libs/oidc';
import { ESQUENTATOR_ACCESSED } from 'libs/trackingEvents';
import { AuthProvider } from 'oidc-react';
import { environment, logger } from 'packs-template-baseweb';
import AssistantTester from 'pages/AssistantTester';
import AsyncPreparation from 'pages/AsyncPreparation/AsyncPreparation';
import Dashboard from 'pages/Dashboard';
import FacebookCatalog from 'pages/FacebookCatalog';
import Faq from 'pages/Faq';
import FaqWizard from 'pages/FaqWizard';
import Instagram from 'pages/Instagram';
import PrivateReply from 'pages/PrivateReply';
import QuickAccess from 'pages/QuickAccess';
import Survey from 'pages/Survey';
import React, { useEffect, useState } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MSClarity } from 'services/Clarity/Clarity';
import 'styles.scss';
import { getUTMParametersFromLocation } from 'utils/queryParams';

logger.configure();
logger.info(`Current enviromenment: ${environment.currentEnvironment}`);
logger.info(`Current log level: ${logger.currentLevel}`);
const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-NQGWZ373',
};

const initializeGTM = () => {
  TagManager.initialize(tagManagerArgs);
};

function App() {
  const { track } = useTrack();
  const [isAppReady, setIsAppReady] = useState(false);
  const [utmParams, setUtmParams] = useState<Record<string, string> | null>(null);
  const INITIALIZATION_TIMEOUT = 2500;

  useEffect(() => {
    console.log(`Esquentator v${VERSION}`);
    initializeGTM();
    const sourceUtm = getUTMParametersFromLocation();
    if (sourceUtm && !localStorage.getItem('source')) {
      setUtmParams(sourceUtm);
      localStorage.setItem('source', JSON.stringify(sourceUtm));
    }
    trackLinkOpened();
    setTimeout(() => {
      setIsAppReady(true);
    }, INITIALIZATION_TIMEOUT);
  }, []);

  const trackLinkOpened = () => {
    const utmParams = getUTMParametersFromLocation();
    if (utmParams) {
      track(ESQUENTATOR_ACCESSED, { source: utmParams }, () => {
        setIsAppReady(true);
      });
    } else {
      setIsAppReady(true);
    }
  };

  return isAppReady ? (
    <AuthProvider {...OIDC}>
      <MobileViewProvider>
        <AppProvider utmParams={utmParams}>
          <MSClarity />
          <BrowserRouter>
            <Routes>
              <Route index element={<AsyncPreparation />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/quick-access" element={<QuickAccess />} />
              <Route path="/survey" element={<Survey />} />
              <Route path="/faq-wizard" element={<FaqWizard />} />
              <Route path="/instagram" element={<Instagram />} />
              <Route path="/catalog" element={<FacebookCatalog />} />
              <Route path="/assistant-tester" element={<AssistantTester />} />
              <Route path="/private-reply" element={<PrivateReply />} />
            </Routes>
          </BrowserRouter>
        </AppProvider>
      </MobileViewProvider>
    </AuthProvider>
  ) : (
    <BdsLoadingPage />
  );
}

export default App;
