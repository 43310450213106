import { BdsGrid, BdsPaper } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import './style.scss';
import HelpBanner from 'components/HelpBanner';
import PrivateReplyTest from '../PrivateReplyTest';

function PrivateReplyContainer() {
  const isMobileView = useMobileView();

  return (
    <BdsGrid class={isMobileView ? 'p-size' : undefined} justifyContent={isMobileView ? 'center' : undefined}>
      <BdsGrid justifyContent="center" gap="3" padding={!isMobileView ? '5' : '2'}>
        <PrivateReplyTest />
      </BdsGrid>
      <div className={isMobileView ? 'help-banner' : undefined}>
        <HelpBanner
          moduleName="Private Reply"
          screenName="Private reply"
          defaultMessage="Preciso de ajuda com a funcionalidade Private reply"
          show={true}
        />
      </div>
    </BdsGrid>
  );
}
export default PrivateReplyContainer;
